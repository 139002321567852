<template>
  <div>
    <!-- Start leftpart -->
    <div class="leftpart">
      <div class="leftpart_inner">
        <div class="menu">
          <a class="icon" v-on:click="toggleMenu">
            <img :src="menuIcon" />
          </a>
          <router-link to="/">
            <a
              :class="[$route.path === '/' ? 'active' : '']"
            >
              <span class="menu_content">Home</span>
            </a>
          </router-link>
          <!-- End Home -->
          <router-link to="/about">
            <a
              :class="[$route.path === '/about' ? 'active' : '']"
            >
              <span class="menu_content">About</span>
            </a>
          </router-link>
          <!-- End About -->
          <router-link to="/productions">
            <a
              :class="[$route.path === '/productions' ? 'active' : '']"
            >
              <span class="menu_content">Productions</span>
            </a>
          </router-link>
          <!-- End Portfolio -->
          <router-link to="/news">
            <a
              :class="[$route.path === '/news' ? 'active' : '']"
            >
              <span class="menu_content">News</span>
            </a>
          </router-link>
          <!-- End News -->
          <router-link to="/join">
            <a
              :class="[$route.path === '/join' ? 'active' : '']"
            >
              <span class="menu_content">Join</span>
            </a>
          </router-link>
          <!-- End Join -->
          <router-link to="/contact">
            <a
              :class="[$route.path === '/contact' ? 'active' : '']"
            >
              <span class="menu_content">Contact</span>
            </a>
          </router-link>
          <!-- End Contact -->
        </div>
        <div class="copyright">
          <p>&copy; {{ new Date().getFullYear() }} Cape Town Theatre Company</p>
        </div>
        <!-- End copyright -->
      </div>
    </div>
    <!-- End leftpart -->

    <!-- Start rightpart -->
    <div class="rightpart">
      <div class="rightpart_in">
        <div class="tokyo_tm_section">
          <div class="wrapper" v-bind:class="[routeName]" :style="{ backgroundImage: 'url(' + selectedImage + ')' }">
            <div id="slider-overlay" class="overlay"></div>
            <div class="container">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End rightpart -->

    <!-- Preload slider images -->
    <div style="position: absolute; visibility: hidden; width:0; height:0; overflow:hidden; z-index:-1">
      <img v-for="(image, index) in images" :key="index" :src="image" />
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        images: [],
        selectedImage: null,
        imageIndex: 0,
        menuIcon: require("../assets/img/svg/menu.svg")
      };
    },
    computed: {
      routeName: function () {
        return this.$route.name;
      }
    },
    watch: {
      $route() {
        this.hideMenu();
      }
    },
    methods: {
      randomItem (items) {
        return items[Math.floor(Math.random()*items.length)];
      },
      toggleMenu () {
        var menuElement = document.getElementsByClassName("menu")[0];
        if (menuElement.classList.contains("expanded"))
          menuElement.classList.remove("expanded");
        else
          menuElement.classList.add("expanded");
      },
      hideMenu() {
        document.getElementsByClassName("menu")[0].classList.remove("expanded");
      }
    },
    created() {
      this.imageIndex = Math.floor(Math.random()*this.images.length);
      this.selectedImage = this.images[this.imageIndex];
      clearInterval(this.intervalid1);
      this.intervalid1 = setInterval(function(){
          this.imageIndex += 1;
          if (this.imageIndex >= this.images.length)
            this.imageIndex = 0;
          this.selectedImage = this.images[this.imageIndex];
      }.bind(this), 10000);
    },
    mounted() {
      axios
      .get('https://cttc-strapi.herokuapp.com/api/home?populate=*')
      .then((response) => {
        this.data = response.data.data;
        for (var index in this.data.attributes.Carousel.data) {
          this.images.push(this.data.attributes.Carousel.data[index].attributes.url);
        }
        this.images.sort(() => Math.random() - 0.5);
      });

      var overlay = document.getElementById("slider-overlay");
      overlay.classList.add("fade-out");
      clearInterval(this.fadeInterval);
      this.fadeInterval = setInterval(function(){
          if (overlay.classList.contains("fade-out")) {
            overlay.classList.remove("fade-out");
            overlay.classList.add("fade-in");
          }
          else {
            overlay.classList.remove("fade-in");
            overlay.classList.add("fade-out");
          }
      }.bind(this), 5000);
    }
  };
</script>

<style lang="scss"></style>
